import React from "react";
import { connect } from "react-redux";
import ActionCreator from "../../ActionCreator";
import { withPage } from "../../Page";
import Welcome from "../../Components/Welcome";

class HomePage extends React.Component {
  componentDidMount() {
    let { appActions } = this.props;
    appActions.clearLocalSettingUid();
  }

  render() {
    return <Welcome from="LandingPage" />;
  }
}

export default withPage(
  connect(
    null,
    ActionCreator
  )(HomePage)
);
